export const VALID_HOSTS = ['ashleyfurniture.com', 'wayfair.com', 'mybobs.com'];

export const INPUT_ERRORS = {
  DUPLICATED: 'This url is duplicated, please try a different url',
  PAGE_RESTRICTION: 'Links only from Ashley, Wayfair and Bob’s',
  INVALID_URL: 'Invalid url, please try again with a valid url',
}

export const isValidURL = (url) => {
  let isValid = false;
  let message = '';

  try{
    const urlData = new URL(url);
    if(VALID_HOSTS.includes(urlData.hostname.replace('www.',''))) {
      isValid = true;
    } else {
      message = INPUT_ERRORS.PAGE_RESTRICTION;
    }
  } catch(error) {
    message = INPUT_ERRORS.INVALID_URL;
  }

  return [isValid, message]
}
