import './URLPreview.css';

import { styled } from '@mui/material/styles';

import IconButton from '@mui/material/IconButton';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

import { handleMouseDown } from './../../../utils/events'

const BootstrapIconButton = styled(IconButton)({
  background: '#fff',
  border: 'solid 1px #cac4d0',

  '&:hover': {
    backgroundColor: '#cac4d0',
    boxShadow: 'none',
  },
});

const getItemCount = (id) => {
  switch(id) {
    case 2: return 'Two';
    case 3: return 'Three';
    default: return 'One';
  }
}

const getDomainShortened = (urlStr) => {
  const domain = (new URL(urlStr));
  const parts = domain.hostname.replace('www.','').split('.')
  const finalDomain = Array.isArray(parts) && parts.length > 0 ? parts[0] : parts;

  switch(finalDomain) {
    case 'ashleyfurniture': return 'Ashley';
    case 'wayfair': return 'Wayfair';
    case 'mybobs': return 'Bob’s';
    default: return finalDomain;
  }
}

function URLPreview({url, id, onDeleteFn, isComparing, isMobile}) {
  const isValudURL = !!url

  return (
    <div className={`URLPreview ${isValudURL ? 'URLPreview-valid': 'URLPreview-invalid'} ${isMobile?'URLPreview-mobile':''}`} >
      {
        isValudURL ?
        <>
          <a href={url} target="_blank" rel="noreferrer">
            Item from <span>{getDomainShortened(url)}</span>
          </a>
          <BootstrapIconButton
                aria-label="delete url"
                onClick={onDeleteFn}
                onMouseDown={handleMouseDown}
                edge="end"
                disabled={isComparing}
              >
                <DeleteOutlinedIcon/>
          </BootstrapIconButton>
        </>
        : `Item ${getItemCount(id)}` 
      }
    </div>
  );
}

export default URLPreview;
