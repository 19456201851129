import { Routes, Route } from "react-router-dom"
import Index from "./components/pages/index";
import './App.css';

function App() {
  return (
    <div className="App" >
      <Routes>
        <Route path="/" element={ <Index /> } />
      </Routes>
    </div>
  );
}

export default App;
