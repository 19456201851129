import './initialForm.css';
import { useState } from 'react';
import {useMixpanel} from 'react-mixpanel-browser';

import IntialFormInstruction from './../../molecules/InitialFormInstructions'
import InitialFormURLs from './../../molecules/InitialFormURLs'
import InitialFormCompareButton from './../../molecules/InitialFormCompareButton'
import CompareResult from './../../organisms/CompareResult'
import CompareProducts from './../../organisms/CompareProducts'
import { createSearchParams, useNavigate } from "react-router-dom";

import Container from '@mui/material/Container';

import InitialFormURLValidator from '../../molecules/InitialFormURLValidator';

import useForceUpdate from '../../../utils/hooks/useForceUpdate';
import {URL_COLLECTION_STORAGE_KEY} from '../../../utils/constants';

function InitialForm({urlCollection, setURLCollection, isComparing, hasComparisonError, handleComparing, clearComparisionData, showResult, comparisonData, setShowResult}) {
  const navigate = useNavigate();
  const [isDirtyComparing, setIsDirtyComparing] = useState(false);
  const forceUpdate = useForceUpdate();
  const mixpanel = useMixpanel();

  const handleSaveCollection = (newCollection) => {
    setURLCollection(newCollection);
    localStorage.setItem(URL_COLLECTION_STORAGE_KEY, JSON.stringify(newCollection));
    navigate({
      pathname: "/",
      search: `?${createSearchParams({
          urls: encodeURIComponent(JSON.stringify(newCollection)),
        })}`
      });
  }

  const handleDeleteURLFromCollection = (onDeleteURL, comparisionId) => {
    const id = urlCollection.findIndex(url => url === onDeleteURL)
    if (id!== -1) {
      const urlRemoveTrackerProps = {url: urlCollection[id] }
      if(!!comparisionId) {
        urlRemoveTrackerProps['comparison_id'] = comparisionId;
      }
      mixpanel.track('URL Removed', urlRemoveTrackerProps);

      urlCollection.splice(id,1)
      handleSaveCollection(urlCollection);
      forceUpdate();
    }
  }

  return (
    <section className="InitialForm-container">
      <Container maxWidth="lg">
        <div className="InitialForm-instructions">
          <IntialFormInstruction hasError={hasComparisonError} />
        </div>

        <div className="InitialForm-url">
          <InitialFormURLValidator
            urlCollection={urlCollection}
            setShowResult={setShowResult}
            handleSaveCollection={handleSaveCollection}
            isComparing={isComparing}
          />
        </div>


        {
        showResult ? (
          <div className="InitialForm-results">
            <CompareProducts
              comparisionId={comparisonData.id}
              products={comparisonData.urls}
              clearComparisionData={clearComparisionData}
              handleDeleteURLFromCollection={handleDeleteURLFromCollection}
              isDirtyComparing={isDirtyComparing}
              setIsDirtyComparing={setIsDirtyComparing}
              />
            <CompareResult comparisonResults={comparisonData.recommendations} />
          </div>
          ) : (
            <div className="InitialForm-items">
              <InitialFormURLs urls={urlCollection} onDeleteFn={handleDeleteURLFromCollection} isComparing={isComparing}/>
            </div>
          )
        }

        <div className="InitialForm-controls">
          <InitialFormCompareButton
            isEnabled={(Array.isArray(urlCollection) && urlCollection.length >= 2)}
            isShowingResults={showResult}
            onClickFn={handleComparing}
            isComparing={isComparing}
          />
        </div>
      </Container>
    </section>
  );
}

export default InitialForm;
