import {useState} from 'react';
import {useMixpanel} from 'react-mixpanel-browser';

import Box from '@mui/material/Box';

import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { handleMouseDown } from './../../../utils/events'
import useIsMobile from '../../../utils/hooks/useIsMobile';
import { LIMIT_PRODUCTS } from '../../../utils/constants'
import { INPUT_ERRORS, VALID_HOSTS } from '../../../utils/urls';

const BootstrapFab = styled(Fab)({
  background: '#6D42FF',
  color: '#fff',
  boxShadow: '0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)',

  '&:hover': {
    backgroundColor: '#744cf7',
    boxShadow: 'none',
  },

  '&:disabled': {
    background: '#E3E3E4',
    color: '#989799',
    boxShadow: 'none',
  },
});

function InitialFormURLValidator({urlCollection, handleSaveCollection, isComparing, setShowResult}) {
  const isMobile = useIsMobile();
  const deviceLimit = isMobile ? LIMIT_PRODUCTS.MOBILE : LIMIT_PRODUCTS.DESKTOP;

  const mixpanel = useMixpanel();
  const canAddToCollection = urlCollection.length < deviceLimit;

  const [url, setURL] = useState('');
  const [isInvalidURL, setIsInvalidURL] = useState(false);
  const [error, setError] = useState('');


  const handleClickClearButton = () => {
    setURL('');
    setIsInvalidURL(false);
    setError('');
  }

  const setNoErrorHandler = (url) => {
    handleClickClearButton();
    setShowResult(false); // IMPORTANT: It hide results if you add a new url and show small cards for url
    // traker
    mixpanel.track('URL Added', {url: url, source: 'user', is_valid_url: true});
  }

  const setErrorHandler = (_url, errorMessage) => {
    setIsInvalidURL(true);
    setError(errorMessage);
    // traker
    mixpanel.track('URL Added', {url: _url, source: 'user', is_valid_url: false, error_message: errorMessage});
  }

  const validateURL = () => {
    try{
      const urlData = new URL(url);
      if(VALID_HOSTS.includes(urlData.hostname.replace('www.','')) && urlCollection.length < 3) {
        if(urlCollection.findIndex(_url => _url === url) !== -1){
          setErrorHandler(url, INPUT_ERRORS.DUPLICATED)
        } else {
          setNoErrorHandler(url)          
          handleSaveCollection([...urlCollection, url]);
        }
      } else {
        setErrorHandler(url, INPUT_ERRORS.PAGE_RESTRICTION);
      }
    } catch(error) {
      setErrorHandler(url, INPUT_ERRORS.INVALID_URL);
    }
  }

  return (
    <Grid container spacing={2} justifyContent="center" >
      <Grid item xs={10} sm={11} >
        <FormControl fullWidth sx={{ m: 1}} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-url">Item’s Link</InputLabel>
          <OutlinedInput
            id="outlined-adornment-url"
            type="url"
            value={url}
            error={isInvalidURL}
            onChange={(newValue) => {
              setURL(newValue.target.value);
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="delete url"
                  onClick={handleClickClearButton}
                  onMouseDown={handleMouseDown}
                  edge="end"
                >
                  <HighlightOffIcon />
                </IconButton>
              </InputAdornment>
            }
            label="Item’s Link"
          />
          <span className="InitialForm-url-helpText">{error}</span>
        </FormControl>
      </Grid>
      <Grid item xs={2} sm={1} >
        <Box sx={{ 
          paddingTop: '6px',
          textAlign: 'right',
          '& > :not(style)': { m: 1 } 
          }}>
          <BootstrapFab
            size="small"
            aria-label="add"
            onClick={validateURL} disabled={!canAddToCollection || isComparing}
            >
            <AddIcon />
          </BootstrapFab>
        </Box>  
      </Grid>
    </Grid>
  );
}

export default InitialFormURLValidator;
