import './fadeTexts.css';
import { useEffect, useState, useMemo } from 'react';

const MILISECONDS_TEXT_CHANGE = 5000;

function FadeTexts({listTexts}) {
  const [counter, setCounter] = useState(0);
  const totalTexts = listTexts.length;
  const buttonText = useMemo(() => {
    return listTexts[counter % totalTexts]
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter]);


  useEffect(() => {
    const timer = setTimeout(() => {
      setCounter(counter + 1);
    }, MILISECONDS_TEXT_CHANGE);
    return () => clearTimeout(timer)
  }, [counter]);

  return (
    <span className='FadeTexts'>{buttonText}</span>
  );
}

export default FadeTexts;
