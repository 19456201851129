import './compareProducts.css';

import ProductPreview from './../../atoms/ProductPreview';
import ProductInfo from './../../atoms/ProductInfo';
import { useEffect, useState } from 'react';
import useForceUpdate from '../../../utils/hooks/useForceUpdate'
import useIsMobile from '../../../utils/hooks/useIsMobile';
import { LIMIT_PRODUCTS } from '../../../utils/constants'

const dataAdapeter = (product) => {
  const {
    product_price: price,
    comfort_score: comfortScore,
    quality_score: qualityScore,
    interior_design_style: interiorDesignStyle,
    utility_features: utilityFeatures,
    shopper_suitability: shopperSuitability,
    dimensions,
    included_items: includedItems,
    care_tips: careTips,
  } = product;

  return [
    {
      name: 'Price',
      // TODO: backend should return price in the correct format
      value: price ? parseFloat(price.replace('$', '')).toLocaleString('en-US', {style: 'currency', currency: 'USD'}) : "Price Unavailable",
    },
    {
      name: 'Comfort Score',
      // TODO: backend should return `comfort_score` as a Float
      value: comfortScore ? `${parseFloat(comfortScore).toFixed(1)} / 5` : 'N/A',
    },
    {
      name: 'Quality Score',
      // TODO: backend should return `quality_score` as a Float
      value: qualityScore ? `${parseFloat(qualityScore).toFixed(1)} / 5` : 'N/A',
    },
    {
      name: 'Interior Design Style',
      value: interiorDesignStyle,
    },
    {
      name: 'Utility Features',
      value: utilityFeatures,
      className: 'long-text',
    },
    {
      name: 'Shopper Suitability',
      value: shopperSuitability,
      className: 'long-text',
    },
    {
      name: 'Dimensions',
      value: dimensions,
      className: 'long-text',
    },
    {
      name: 'Included Items',
      value: includedItems,
      className: 'long-text',
    },
    {
      name: 'Care Tips',
      value: careTips,
      className: 'long-text',
    },
  ];
}

function CompareProducts({comparisionId, products, clearComparisionData, handleDeleteURLFromCollection, isDirtyComparing, setIsDirtyComparing}) {
  const forceUpdate = useForceUpdate();
  const [localProducts, setLocalProducts] = useState([])
  const isMobile = useIsMobile();
  const deviceLimit = isMobile ? LIMIT_PRODUCTS.MOBILE : LIMIT_PRODUCTS.DESKTOP;

  useEffect(() => {
    setLocalProducts(products)
    setIsDirtyComparing(false);
  }, [products, setIsDirtyComparing]);

  useEffect(() => {
    if(isDirtyComparing && localProducts.length === 0) {
      clearComparisionData();
      forceUpdate();
    }
  }, [clearComparisionData, forceUpdate, isDirtyComparing, localProducts.length])

  const handleLocalDelete = (url) => {
    const urlIndex = localProducts.findIndex((item) => item.url === url);
    if(urlIndex !== -1) {
      setIsDirtyComparing(true);
      handleDeleteURLFromCollection(url, comparisionId);
      localProducts.splice(urlIndex,1)
      setLocalProducts(localProducts);
      forceUpdate();
    }
  }

  const fillEmpty = (items) => {
    const urlLength = localProducts.length > deviceLimit ? deviceLimit : localProducts.length;
    const emptyItems = Array(deviceLimit - urlLength).fill(0);
    
    return emptyItems.map((_,index) => <li className="noDataItem" key={`CompareProducts_empty_${index}`}>
        <ProductPreview index={index} />
        <ProductInfo />
      </li>);
  }

  return (
    <ul className="CompareProducts">
      {localProducts.map((product , index) => {
        const {
          product_name: productName,
          product_image_url: imageUrl,
          retailer_name: retailerName,
          url,
        } = product;

        if(isMobile && index >= LIMIT_PRODUCTS.MOBILE ) return null;
        const productInfoData = dataAdapeter(product);

        return (<li key={`CompareProducts_${index}`}>
          <ProductPreview
            imageUrl={imageUrl}
            productName={productName}
            productUrl={url}
            retailerName={retailerName}
            onDelete={handleLocalDelete}
            comparisionId={comparisionId}
            />
          <ProductInfo data={productInfoData} />
        </li>)
      })}
      {
        fillEmpty(localProducts)
      }
    </ul>
  );
}

export default CompareProducts;
