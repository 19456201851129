import { styled } from '@mui/material/styles';
import LoadingButton from '@mui/lab/LoadingButton';

import FadeTexts from '../../atoms/FadeTexts';

const BootstrapLoadingButton = styled(LoadingButton)({
  width: '300px',
  color: '#FFF',
  borderRadius: '100px',
  background: '#6D42FF',
  boxShadow: '0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)',

  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: '500',
  padding: '10px 24px',
  textTransform: 'capitalize',

  '&:hover': {
    backgroundColor: '#744cf7',
    boxShadow: 'none',
  },

  '&:disabled': {
    background: '#E3E3E4',
    color: '#989799',
    boxShadow: 'none',
  },
});

const COMPARING_TEXTS = [
  'Comparing Materials...',
  'Comparing Features...',
  'Comparing Comfort...',
  'Comparing Style...',
  'Comparing Quality...',
  'Still Working...',
  'Almost There...',
  'Finalizing Details...',
  'Publishing Details...',
  'Almost There...',
];


function ComparingButton({isComparing, onClickFn, isDisabled}) {
  return (
    <BootstrapLoadingButton
    loading={isComparing}
    loadingPosition="start"
    startIcon={<></>}
    variant="outlined"
    onClick={onClickFn}
    disabled={isDisabled}
  >
    {
      isComparing
      ? <FadeTexts listTexts={COMPARING_TEXTS} />
      : <span className='ComparingButton_text'>Compare</span>
    }
  </BootstrapLoadingButton>
  );
}

export default ComparingButton;
