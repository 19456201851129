import {MixpanelProvider} from 'react-mixpanel-browser';

import useURLQuery from '../../utils/hooks/useURLQuery';
import CompareURLs from '../templates/CompareURLs';
import { isValidURL } from '../../utils/urls';

// Mixpanel Event Tracking
const devToken = '42e12be09df4643d80d45e0af9671368';
const prodToken = '953c691a98e6297a182426b74f515e81';
const mixpanelToken = process.env.NODE_ENV === 'production' ? prodToken : devToken;
const UUID_REGEX = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

const mixpanelConfig = {
  track_pageview: true,
};


const getURLParams = (urlsParam) => {
  let results = [];

  if(!!urlsParam) {
    const tmpResult = JSON.parse(decodeURIComponent(urlsParam));

    if(Array.isArray(tmpResult)) {
      tmpResult.forEach((url) => {
        const [isValid] = isValidURL(url);
        if(isValid) {
          results.push(url);
        }
      })
    }
  }

  return results;
}

const getCompareIdParams = (compareId) => {
  let result;

  if(!!compareId && UUID_REGEX.test(compareId)) {
    result = compareId;
  }

  return result;
}

function Index() {
  let query = useURLQuery();
  const urls = getURLParams(query.get('urls'));
  const compareIDFromURL = getCompareIdParams(query.get('compare_id'));
  
  return (
    <MixpanelProvider config={mixpanelConfig} token={mixpanelToken}>
      <CompareURLs urls={urls} compareIDFromURL={compareIDFromURL} />
    </MixpanelProvider>
  );
}

export default Index;
