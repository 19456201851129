import * as React from "react";
import {useEffect} from "react";

// Viral Loops Widget Hack
// @viral-loops/widgets has a compiler issue because they're not compiling their code, dupe it here and fixed it
export default function ViralLoopsWidget({ucid, popup}) {
  function loadViralLoops() {
    const SCRIPT_SRC = "https://app.viral-loops.com/widgetsV2/core/loader.js";
    const scriptExists = document.head.querySelector(`script[src="${SCRIPT_SRC}"]`);
    if (scriptExists) {
      return Promise.resolve();
    }
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = SCRIPT_SRC;
      script.onload = resolve;
      script.onerror = reject;
      document.head.appendChild(script);
    });
  }

  useEffect(() => {
    loadViralLoops();
  }, []);

  return (
    <>
      {ucid ? (
        <form-widget ucid={ucid} mode={popup} />
      ) : null}
    </>
  );
}

ViralLoopsWidget.defaultProps = {};