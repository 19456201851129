import './intialFormInstruction.css';
import Card from '@mui/material/Card';
import {CardContent} from '@mui/material';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';
import useIsMobile from '../../../utils/hooks/useIsMobile';
import { styled } from '@mui/material/styles';
import { material_ui_custom_styles } from '../../../utils/constants';

const StyledList = styled('li')(({theme}) => ({
  ...material_ui_custom_styles.commonTexts.desktop,
  [theme.breakpoints.down("sm")]: {
    ...material_ui_custom_styles.commonTexts.mobile,
  }
}));

const StyledCard =  styled(Card)(() => ({
  backgroundColor: '#EEE7F1',
  padding: '20px 30px',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '30.5px',
}));

const StyledCardContent =  styled(CardContent)(({theme}) => ({
  padding: '0 !important',
  "& h1": {
    margin: '0 0 10px',
  },

  "& p": {
    margin: '0',
  },

  ...material_ui_custom_styles.commonTexts.desktop,
  [theme.breakpoints.down("sm")]: {
    ...material_ui_custom_styles.commonTexts.mobile,
  }
}));

const StyledAlert = styled(Alert)(() => ({
  color: '#492C2A',
  backgroundColor: '#fde8e9',
  padding: '13px 20px',
  fontSize: '16px',
  fontWeight: '400',
  lineHeight: '30.5px',

  "& .MuiAlert-icon": {
    color: '#CA2743',
    "& > svg": {
      width: '28px',
      height: '28px',
    }
  },
})); 

const StyledAlertTitle = styled(AlertTitle)(() => ({
  fontSize: '20px',
  fontStyle: 'normal',
  lineHeight: '30.5px',
})); 

function IntialFormInstruction({hasError = false}) {
  const isMobile = useIsMobile();
  return (
    <div>
      <StyledCard elevation={0}>
        <StyledCardContent>
          <h1>Welcome to our Friends and Family Prototype</h1>
          <p>We've created a tool to compare products from different retailers without juggling multiple browser tabs. Our simple furniture comparison prototype is here, though it may take up to 60 seconds to process. If you encounter an error, try with a different set of products. Your feedback is greatly appreciated. Thanks for your support!</p>
        </StyledCardContent>
      </StyledCard>
      {hasError && (
        <Stack sx={{ width: '100%', marginTop: '50px', }} spacing={2}>
          <StyledAlert variant="filled" severity="error" >
            <StyledAlertTitle>Error</StyledAlertTitle>
            Oops, we had an issue getting your comparison together. We’re still working out the kinks of our prototype - <b>Please try another set of products or try again later.</b>
          </StyledAlert>
        </Stack>
      )}
      <ol className="IntialFormInstruction">
        <StyledList>
          Copy {isMobile ? '2' : '2 to 3'} product links from &nbsp;
          <a href="https://www.ashleyfurniture.com/" target='_blank' rel="noreferrer">ashleyfurniture.com</a>,&nbsp;
          <a href="https://www.wayfair.com/" target='_blank' rel="noreferrer">wayfair.com</a> and &nbsp;
          <a href="https://www.mybobs.com/" target='_blank' rel="noreferrer">mybobs.com</a> and tap on plus button to add them
        </StyledList>
        <StyledList>
          Tap on Compare Details button to get a detailed side by side comparison of the products
        </StyledList>
        <StyledList>
          Make your next big purchase with confidence!
        </StyledList>
      </ol>
    </div>
  );
}

export default IntialFormInstruction;
