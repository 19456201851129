import Accordion from './../../molecules/Accordion';
import './compareResult.css';
import Container from '@mui/material/Container';

function CompareResult({comparisonResults}) {
  // TODO: find the newest comparisonResults timestamp
  const summary = comparisonResults && comparisonResults.length > 0 && comparisonResults[0]?.summary;
  return (
    <section className="CompareResult">
      <Container maxWidth="lg" style={{ padding: '0',}}>
        <Accordion summary={summary} />
      </Container>
    </section>
  );
}

export default CompareResult;
