

import Button from '@mui/material/Button';

import noImageSrc from './../../../no-image-card.png';

import { styled } from '@mui/material/styles';

import IconButton from '@mui/material/IconButton';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

import { handleMouseDown } from './../../../utils/events'

import { useMixpanel } from 'react-mixpanel-browser';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import useIsMobile from '../../../utils/hooks/useIsMobile';

import './productPreview.css';

const StyledCard = styled(Card)({
  background: '#F8F7F9',
  borderRadius: '28px',
  border: '1px solid #CAC4D0',
  boxShadow: 'none',
  height: '300px',
  width: '100%',
})

const StyledEmptyCard = styled(StyledCard)({
  background: '#fff',
  border: '1px dashed #CAC4D0',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#CAC4D0',

  fontSize: '24px',
})


const BootstrapIconButton = styled(IconButton)({
  background: '#fff',
  border: 'solid 1px #cac4d0',

  '&:hover': {
    backgroundColor: '#cac4d0',
    boxShadow: 'none',
  },
});


const BootstrapButton = styled(Button)(({theme}) => ({
  width: '70px',
  color: '#000',
  borderRadius: '100px',
  background: '#D9CEFF',
  border: 'none',
  
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '500',
  padding: '10px 24px',
  textTransform: 'capitalize',

  '&:hover': {
    border: 'none',
    backgroundColor: '#c7b8fc',
    boxShadow: 'none',
  },

  '&:disabled': {
    background: '#E3E3E4',
    color: '#989799',
    boxShadow: 'none',
  },

  [theme.breakpoints.down("sm")]: {
    width: '100px',
  }

}));

const StyledCardActions = styled(CardActions)(({theme}) => ({
  [theme.breakpoints.down("sm")]: {
    flexDirection: 'column',
  }
}));

const getItemCount = (id) => {
  switch(id) {
    case 1: return 'Two';
    case 2: return 'Three';
    default: return 'One';
  }
}

function ProductPreview(productData) {
  const {index = 0, ...rest } = productData;
  const isMobile = useIsMobile();
  const mixpanel = useMixpanel();
  const {imageUrl, productName, productUrl, retailerName, onDelete, comparisionId} = rest;
  const onDeleteFn = (url) => ()=> {
    onDelete(url)
  }

  const onBuyButtonHandler = () => {
    mixpanel.track('Buy Button Clicked', {url: productUrl, comparison_id: comparisionId});
  }
  
  return Object.keys(rest).length > 0 ? (
    <StyledCard>
      <CardMedia
        component="img"
        height={isMobile ? "170" : "225"}
        image={imageUrl || noImageSrc}
        alt="Product Card"
      />
      <StyledCardActions className="ProductPreview-actions">
        <div className="ProductPreview-info">
          <span>{retailerName}</span>
          <b>{productName}</b>
        </div>
        <div className="ProductPreview-btns">
          <a href={productUrl} target="_blank" rel="noreferrer">
            <BootstrapButton variant="outlined" onClick={onBuyButtonHandler}>
              buy
            </BootstrapButton>
          </a>
          <BootstrapIconButton
            aria-label="delete url"
            onClick={onDeleteFn(productUrl)}
            onMouseDown={handleMouseDown}
            edge="end"
          >
            <DeleteOutlinedIcon />
          </BootstrapIconButton>
        </div>
      </StyledCardActions>
    </StyledCard>
  ) : (<StyledEmptyCard >Item {getItemCount(index)}</StyledEmptyCard>)
}

export default ProductPreview;
