import logoSrc from './../../../iota-logo.png';
import './logo.css';

function Logo() {
  return (
    <div className="Logo_container">
      <img src={logoSrc} className="Logo-img" alt="iota logo" />
      <div className="Logo-beta">beta</div>
    </div>
  );
}

export default Logo;
