import './footer.css';
import ViralLoopsWidget from './../../../utils/viral-loops';
import Container from '@mui/material/Container';
import { styled } from "@mui/material/styles";

const StyledContainer = styled(Container)(({ theme }) => ({
  padding: '50px 0 100px',

  [theme.breakpoints.down("sm")]: {
    padding: '50px 10px',
    '& .Footer-copyright': {
      paddingTop: '30px',
    },
  },
}));

function Footer() {
  const VIRAL_LOOPS_CAMPAIGN_ID = 'jaYnpjbIJxwwH7STkHGoSdqnRpk';

  return (
    <footer className="Footer-container">
      <StyledContainer maxWidth="lg">
        <div className="Footer-text">
          Soon, you will be able to compare items from any retailer! Sign up to get the latest updates!
        </div>
        <div className="Footer-form-container">
          <ViralLoopsWidget ucid={VIRAL_LOOPS_CAMPAIGN_ID} mode={"popup"} />
        </div>
        <div className="Footer-copyright">
          Iota is an experiment built by <a href="https://uplabs.us/" target='_blank' rel="noreferrer">UP.Labs</a> | Copyright &copy; UP.Labs 2023
        </div>
      </StyledContainer>
    </footer>
  );
}

export default Footer;
