import Logo from './../../molecules/Logo';
import Container from '@mui/material/Container';
import { styled } from "@mui/material/styles";

import './header.css';

const StyledContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    '& .Heacer-content': {
      flexDirection: 'column',
      '& .Logo_container': {
        alignSelf: 'flex-start',
        '& img': {
          height: '30px',
        },
        '& .Logo-beta': {
          fontSize: '7px',
        }
      },
    },
    '& .Header-text': {
      fontSize: '16px',
    },
  },
}));


function Header() {
  return (
    <header className="Header">
      <StyledContainer maxWidth="lg">
        <div className="Heacer-content">
          <Logo />
          <div className="Header-text">
            Compare furniture and make the right choice!
          </div>
        </div>
      </StyledContainer>
    </header>
  );
}

export default Header;
