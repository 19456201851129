import './accordion.css';
import { styled } from '@mui/material/styles';
import { material_ui_custom_styles } from '../../../utils/constants';

const StyledContent = styled('div')(({theme}) => ({
  ...material_ui_custom_styles.commonTexts.desktop,
  [theme.breakpoints.down("sm")]: {
    ...material_ui_custom_styles.commonTexts.mobile,
  }
}));

function Accordion({summary}) {
  return (
    <div className="Accordion">
      <StyledContent className="Accordion-description Accordion-main">
        <span className="Accordion-description-title">Comparison Summary</span>
        <p className="Accordion-description-text">
          {summary || "Oops, we're sorry! We weren't able to generate a smart summary. We're still in beta and sometimes early technology doesn't function as planned. We're working hard to improve our algorithms to build a better experience for you."}
        </p>
      </StyledContent>
    </div>
  );
}

export default Accordion;
