import './productInfo.css';

function ProductInfo(props) {
  const {data = []} = props;
    return (
      <div className='ProductInfo'>
        <div className={`ProductInfo-values ${Object.keys(props).length === 0 ? 'ProductInfo-values-empty':'' }`} >
          {data.map((field, index) => (
            <div className='ProductInfo-row' key={index}>
              <div className='ProductInfo-container'>
                <div className={`ProductInfo-container-text ${field.className || ''}`}>
                  <div className='ProductInfo-field-name'>{field.name}</div>
                  <div className='ProductInfo-field-value'>{field.value || 'Unavailable'}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
}

export default ProductInfo;
