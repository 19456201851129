import './initialFormURLs.css';

import URLPreview from './../../atoms/URLPreview';
import useIsMobile from '../../../utils/hooks/useIsMobile';

function InitialFormURLs({urls, onDeleteFn, isComparing}) {
  const isMobile = useIsMobile();
  const [url1 = '', url2= '', url3=''] = urls;
  return (
    <ul className={`InitialFormURLs ${isMobile ? 'InitialFormURLs-mobile': ''}`}>
      <li><URLPreview url={url1} id={1} onDeleteFn={()=> onDeleteFn(url1)} isComparing={isComparing} isMobile={isMobile}/> </li>
      <li><URLPreview url={url2} id={2} onDeleteFn={()=> onDeleteFn(url2)} isComparing={isComparing} isMobile={isMobile}/> </li>
      {!isMobile && (
        <li><URLPreview url={url3} id={3} onDeleteFn={() => onDeleteFn(url3)} isComparing={isComparing} /> </li>
      )}
    </ul>
  );
}

export default InitialFormURLs;
