export const URL_COLLECTION_STORAGE_KEY =  'url_collection_storage_key';

export const LIMIT_PRODUCTS = {
  DESKTOP: 3,
  MOBILE: 2
};

export const AXIOS_PARAMETHERS = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
};

export const material_ui_custom_styles = {
  commonTexts: {
    desktop: {
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '160%',
    },
    mobile: {
      fontSize: '14px',
    }
  }
};
