import axios from "axios";
import { useEffect, useState } from 'react'
import { useMixpanel } from 'react-mixpanel-browser';
import _refiner from 'refiner-js';

import Header from './../../organisms/Header';
import Footer from './../../organisms/Footer';
import InitialForm from './../../organisms/InitialForm';

import useWebSocket, { ReadyState } from 'react-use-websocket';
import { AXIOS_PARAMETHERS, URL_COLLECTION_STORAGE_KEY } from './../../../utils/constants'
import { createSearchParams, useNavigate } from "react-router-dom";

import './compareURLs.css';

const COMPARE_BASE_URL = "https://5p9508jo0h.execute-api.us-west-2.amazonaws.com/prod/v1/comparison";
const COMPARE_WS_URL = "wss://uplabs-dvl-iota.directus.app/websocket";
const API_KEY = 'f2jbmvkwZH0dxeD5dJYHVJk49ffz7-qW';

let sendHandShake = true;

function CompareURLs({urls, compareIDFromURL}) {
  const navigate = useNavigate();
  const mixpanel = useMixpanel();
  const [urlCollection, setURLCollection] = useState([]);
  const [isComparing, setIsComparing] = useState(false);

  const [compareId, setCompareId] = useState();
  const [socketUrl, setSocketUrl] = useState(null);
  const [comparisonData, setComparisonData] = useState([]);
  const [hasComparisonError, setHasComparisonError] = useState(false);

  _refiner('setProject', '645b5800-6c7e-11ee-8dd8-37c81b73f040');

  const {sendMessage, readyState } = useWebSocket(socketUrl, {
    onClose: async () => {
      const eventTrackingProps = {}

      try {
        const {status: resStatus, data} = await axios.get(`${COMPARE_BASE_URL}/${compareId}`);
        eventTrackingProps.comparison_id = data.id;
        eventTrackingProps.urls = data.urls;
        eventTrackingProps.recommendations = data.recommendations;
        setIsComparing(false);

        if (resStatus !== 200 || data.status === 4) {
          setHasComparisonError(true);
          console.error('GET comparison - error:', data);
          eventTrackingProps.is_successful_comparison = false;
          eventTrackingProps.error_message = data.error_message;
        } else {
          // response OK
          setHasComparisonError(false);
          console.log(`GET Comparison - response.data:`, data);          
          setShowResult(true);
          setComparisonData(data);
          navigate({
            pathname: "/",
            search: `?${createSearchParams({
              compare_id: compareId,
            })}`
        });
          eventTrackingProps.is_successful_comparison = true;
        }
      } catch (error) {
        setHasComparisonError(true);
        console.error('GET comparison - error:', error);
        eventTrackingProps.is_successful_comparison = false;
        eventTrackingProps.error_message = error;
      }
      mixpanel.track('Comparison Completed', eventTrackingProps);
      _refiner('showForm', '88f50870-6c7e-11ee-bf88-cd24a671a3c4', true);
    }
  });

  const [showResult, setShowResult] = useState(false);

  const handleWSComparing = (compareId) => {
    const socketParams = JSON.stringify({
      type: 'subscribe',
      collection: 'comparison',
      event: 'update',
      item: compareId,
      query: { 
          fields: ['*']
      }
    });
    sendMessage(socketParams);
  }

  const handleComparing = async () => {
    if(urlCollection.length === 2 || urlCollection.length === 3 ){
      mixpanel.track('Compare Button Clicked', {urls: urlCollection});
      setIsComparing(true);
      setSocketUrl(COMPARE_WS_URL);
      await axios.post(COMPARE_BASE_URL, {
        urls: urlCollection,
      }, AXIOS_PARAMETHERS)
        .then((response) => {
          const _compareId = response?.data?.comparison_id;
          setCompareId(_compareId);
          handleWSComparing(_compareId);
        });
    }
  }

  const clearComparisionData = () => {
    setURLCollection([]);
    setComparisonData([]);
    setShowResult(false);
  }

  useEffect(() => {
    if(ReadyState.OPEN === readyState && sendHandShake) {
      sendHandShake = false;
      const handShakeProps = JSON.stringify({ 
        type: 'auth', 
        access_token: API_KEY, 
      });
      sendMessage(handShakeProps);
    }

    if(ReadyState.CLOSED === readyState && sendHandShake === false) {
      sendHandShake = true;
      setSocketUrl(null);
    }
  }, [readyState, sendMessage]);

  //fill urlCollection from paramethers
  useEffect(() => {
    if(Array.isArray(urls) && urls.length > 0) { // from url
      setURLCollection(urls);
    } else { // from localStore
      const urlFromStorage = JSON.parse(localStorage.getItem(URL_COLLECTION_STORAGE_KEY));
      if (urlFromStorage) {
        setURLCollection(urlFromStorage);
        urlFromStorage.forEach((url) => {
          mixpanel.track('URL Added', {url: url, source: 'localStorage', valid_url: true});
        });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[urls])

//fill urlCollection from paramethers
useEffect(() => {
  async function fetchData(_compareId) {
    try{
      const {status: resStatus, data} = await axios.get(`${COMPARE_BASE_URL}/${_compareId}`);

      if (resStatus === 200 && data.status !== 4) {
        setShowResult(true);
        setComparisonData(data);
      }
    } catch(error) {
      console.error('compare_id - error:', _compareId, error);
    }
  }

  if(!!compareIDFromURL) {
    fetchData(compareIDFromURL);
  }

},[compareIDFromURL])
  

  const initialFormProps = {
    urlCollection, 
    setURLCollection,
    isComparing,
    hasComparisonError,
    comparisonData,
    clearComparisionData,
    handleComparing,
    showResult,
    setShowResult,
  }
  
  return (
    <div className="CompareURLs-container">
      <Header />
      <InitialForm {...initialFormProps} />
      <Footer />
    </div>
  );
}

export default CompareURLs;
