import ComparingButton from '../ComparingButton'

function InitialFormCompareButton({isEnabled, onClickFn, isComparing, isShowingResults}) {
  return (<ComparingButton 
    isComparing={isComparing}
    isDisabled={!isEnabled || isShowingResults}
    onClickFn={onClickFn}
  />);
}

export default InitialFormCompareButton;
